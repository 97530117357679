import type { CookieConfig } from '@/helpers/interfaces'

export const AUTH_TOKEN_COOKIE = 'gs_auth' as const

export function setCookie(config: CookieConfig) {
  const { name, value, days, domain, httpOnly } = config
  const date = new Date()
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + date.toUTCString()
  const domainString = domain ? `;domain=${domain}` : ''
  const httpOnlyString = httpOnly ? ';HttpOnly' : ''
  document.cookie = name + '=' + value + ';' + expires + ';path=/' + domainString + httpOnlyString
}

export function getCookie(name: string): string | undefined {
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim()
    const [cookieName, cookieValue] = cookie.split('=')
    if (cookieName === name) {
      return decodeURIComponent(cookieValue)
    }
  }
  return undefined
}

export function clearCookie(name: string, domain?: string) {
  const expires = 'expires=Thu, 01 Jan 1970 00:00:00 UTC'
  const domainString = domain ? `;domain=${domain}` : ''
  document.cookie = name + '=;' + expires + ';path=/' + domainString
}
