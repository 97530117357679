import { defineStore } from 'pinia'
import {
  getPublicAccount,
  getProjects,
  getReceipts,
  getCommunityAccount,
  getPurchases,
} from '@/api'
import type {
  ImpactType,
  IProject,
  IProjectRequestParams,
  ITransferredImpact,
  TopStat,
} from '@/api/interfaces'
import type {
  CarbonComparison,
  IReceiptTransferred,
  KelpComparison,
  PlasticComparison,
  PublicPageData,
  PublicStatData,
  TreeComparison,
  WaterComparison,
} from '@/stores/interfaces/PublicData'
import tolgee from '@/plugins/tolgee'
import { dispatchMessage, EVENT_TYPES } from '@/composables/iframe'
import { inIFrame, parseImpactsArrayToObject } from '@/helpers/utils'
import { IMPACT_TYPES } from '@/api/interfaces'
import Decimal from 'decimal.js'

export const usePublicData = defineStore('publicData', {
  state: (): PublicPageData => ({
    name: '',
    signupDate: '',
    accountType: 'business',
    projectList: [],
    // TODO: We should change this data structure to allow for a "unloaded" state
    // https://greenspark-force.monday.com/boards/3658967791/views/83096355/pulses/7088681338
    impacts: undefined,
    comparisons: [],
    receipts: [],
    purchases: [],
    loading: false,
    lang: import.meta.env.VITE_FALLBACK_LOCALE,
  }),
  getters: {
    getPublicStats({ impacts }: PublicPageData): PublicStatData {
      return {
        transferredImpacts: parseImpactsArrayToObject(
          (impacts ?? [])
            .filter((i) => i.source === 'transferred')
            .map((i) => ({
              ...i,
              // the carbon impact is presented as kg in the backend, but we display it in tons
              amount: new Decimal(i.amount).dividedBy(i.type === 'carbon' ? 1000 : 1).toNumber(),
            })),
        ),
        relationImpacts: parseImpactsArrayToObject(
          (impacts ?? [])
            .filter((i) => i.source === 'relation')
            .map((i) => ({
              ...i,
              // the carbon impact is presented as kg in the backend, but we display it in tons
              amount: new Decimal(i.amount).dividedBy(i.type === 'carbon' ? 1000 : 1).toNumber(),
            })),
        ),
      }
    },
    getTopStats(s: PublicPageData): { [key in TopStat]: number } {
      const aggregatedImpacts = IMPACT_TYPES.reduce(
        (acc, type) => {
          return {
            ...acc,
            [type]: (s.impacts ?? [])
              .filter((impact) => impact.type === type)
              .reduce(
                (sum, { amount }) =>
                  new Decimal(sum)
                    .add(new Decimal(amount).dividedBy(type === 'carbon' ? 1000 : 1).toNumber())
                    .toNumber(),
                0,
              ),
          }
        },
        {} as Record<ImpactType, number>,
      )

      const date1 = new Date()
      const date2 = s.signupDate ? new Date(s.signupDate) : new Date()

      let difference = (date2.getTime() - date1.getTime()) / 1000
      difference /= 60 * 60 * 24 * 7 * 4

      const monthsEarthPositive = Math.abs(Math.round(difference))
      return { monthsEarthPositive, ...aggregatedImpacts }
    },
    getComparison({ comparisons }: PublicPageData) {
      return <T>(type: ImpactType): T => {
        return comparisons
          .filter((comparison) => type === comparison.type)
          .reduce(
            (acc, { amount, unit, suffix }) => ({
              ...acc,
              [unit]: {
                value:
                  // converting the carbon sequestered amount from kg to tons
                  unit === 'carbonSequestered'
                    ? new Decimal(amount).dividedBy(1000).toNumber()
                    : amount,
                suffix: unit === 'carbonSequestered' ? 't' : suffix,
              },
            }),
            {},
          ) as T
      }
    },
    getComparisons() {
      return (type: ImpactType) => {
        switch (type) {
          case 'carbon':
            return this.getComparison<CarbonComparison>(type)
          case 'plastic':
            return this.getComparison<PlasticComparison>(type)
          case 'trees':
            return this.getComparison<TreeComparison>(type)
          case 'kelp':
            return this.getComparison<KelpComparison>(type)
          case 'water':
            return this.getComparison<WaterComparison>(type)
          default: {
            const exhaustiveCheck: never = type
            throw new Error(exhaustiveCheck)
          }
        }
      }
    },
    getTransferredImpactInvoices(): IReceiptTransferred[] {
      const transferredImpactInvoices: IReceiptTransferred[] = []
      for (const { type, urls } of (this.impacts ?? []).filter(
        (i) => i.source === 'transferred',
      ) as ITransferredImpact[]) {
        for (const url of urls) {
          transferredImpactInvoices.push({ type, url })
        }
      }
      return transferredImpactInvoices
    },
    isBusinessAccount(): boolean {
      return ['business', 'ecommerce'].includes(this.accountType)
    },
    getActiveProjects(s: PublicPageData): IProject[] {
      return s.projectList.filter((project) => project.status === 'approved')
    },
  },
  actions: {
    async fetchPublicData(id: string) {
      try {
        const { data } = await getPublicAccount(id)
        this.$patch({ ...data })

        if (inIFrame()) {
          dispatchMessage(window.parent, { type: EVENT_TYPES.UPDATE_PUBLIC_STORE, payload: data })
        }
      } catch (e) {
        console.error(e)
      }
    },
    async fetchCommunityData() {
      try {
        const { data } = await getCommunityAccount()
        this.$patch({ ...data })
      } catch (e) {
        console.error(e)
      }
    },
    async fetchProjects(params?: IProjectRequestParams) {
      try {
        const { data } = await getProjects({ ...params })
        this.projectList = data
      } catch (e) {
        console.error(e)
      }
    },
    async fetchReceipts() {
      try {
        const { data } = await getReceipts()
        this.receipts = data.sort((a, b) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime()
        })
      } catch (e) {
        console.error(e)
      }
    },
    async fetchPurchases() {
      try {
        const { data } = await getPurchases()
        this.purchases = data
      } catch (e) {
        console.error(e)
      }
    },
  },
})
