import { onMounted, onUnmounted } from 'vue'

import { useEditorStore } from '@/stores/Editor'
import { usePublicData } from '@/stores/PublicData'

export const EVENT_TYPES = {
  INIT: 'INIT',
  UPDATE_EDITOR_STORE: 'UPDATE_EDITOR_STORE',
  UPDATE_PUBLIC_STORE: 'UPDATE_PUBLIC_STORE',
} as const

export type EventType = keyof typeof EVENT_TYPES
export type EventPayload = { [key: string]: any }

export interface IFrameMessage {
  type: EventType
  payload?: EventPayload
}

export const dispatchMessage = (target: Window | null | undefined, message: IFrameMessage) => {
  if (target && target.postMessage) {
    target.postMessage(message, '*')
  }
}

export function useEditorFrame() {
  const editorStore = useEditorStore()
  const publicStore = usePublicData()

  const handleMessage = (event: MessageEvent) => {
    if (event.data) {
      const type: EventType = event.data.type
      const payload: EventPayload = event.data.payload

      switch (type) {
        case EVENT_TYPES.INIT:
          editorStore.loading = false
          break
        case EVENT_TYPES.UPDATE_EDITOR_STORE:
          editorStore.$patch({ ...payload })
          break
        case EVENT_TYPES.UPDATE_PUBLIC_STORE:
          publicStore.$patch({ ...payload })
          break
        default:
      }
    }
  }

  const getIframeEl = () => {
    const iframeEl = document.getElementById('editor-page-preview') as HTMLIFrameElement
    return iframeEl?.contentWindow
  }

  onMounted(() => window.addEventListener('message', handleMessage))
  onUnmounted(() => window.removeEventListener('message', handleMessage))

  return {
    dispatchMessage,
    getIframeEl,
  }
}
