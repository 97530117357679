import { createVuetify, type VuetifyOptions } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'

import 'vuetify/styles'

const vuetifyConfig: VuetifyOptions = {
  defaults: {
    VTextField: {
      hideDetails: 'auto',
    },
    VCheckbox: {
      hideDetails: 'auto',
      color: '#3b755f',
    },
    VRadioGroup: {
      hideDetails: 'auto',
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  theme: {
    defaultTheme: 'light',
  },
}

export default createVuetify(vuetifyConfig)
