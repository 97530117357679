<script setup lang="ts">
import { computed } from 'vue'
import { RouterView } from 'vue-router'
import { TolgeeProvider } from '@tolgee/vue'

import Hotjar from '@hotjar/browser'
import { useEditorStore } from '@/stores/Editor'
import { hexToRgb } from '@/helpers/utils'

const editorStore = useEditorStore()

if (import.meta.env.PROD) {
  Hotjar.init(
    Number(import.meta.env.VITE_HOTJAR_SITE_ID),
    Number(import.meta.env.VITE_HOTJAR_VERSION),
  )
}

const cssVars = computed(() => {
  return {
    '--custom-theme-color': editorStore.editor.background.themeColor.background,
    '--custom-theme-color-rgb': hexToRgb(editorStore.editor.background.themeColor.background),
  }
})
</script>

<template>
  <TolgeeProvider>
    <v-app>
      <v-main :style="cssVars">
        <RouterView />
      </v-main>
    </v-app>
  </TolgeeProvider>
</template>

<style lang="scss">
.v-checkbox {
  .v-selection-control__input > .v-icon {
    opacity: 1;
  }

  .v-selection-control .v-label {
    opacity: 1;
  }
}
</style>
