import { defineStore } from 'pinia'

import type { EditorSettings } from '@/stores/interfaces/Editor'
import { getEditorSettings, updateEditorSettings } from '@/api'
import { BackgroundTheme, type IEditorSettings } from '@/api/interfaces'
import { dispatchMessage, EVENT_TYPES } from '@/composables/iframe'
import { inIFrame } from '@/helpers/utils'

export const useEditorStore = defineStore('editor', {
  state: (): EditorSettings => ({
    editor: {
      background: {
        theme: BackgroundTheme.LIGHT,
        icon: 'leaves',
        themeColor: {
          background: '#8852cb',
          text: '#FFFFFF',
        },
      },
      hero: {
        style: 'center',
        logo: {
          url: '',
          visible: true,
        },
        image: {
          url: '',
          visible: true,
          overlayColor: '#FFFFFF',
          textColor: '#212121',
        },
        content: {
          en: {
            title: '',
            subtitle: '',
          },
          de: {
            title: '',
            subtitle: '',
          },
        },
        links: {
          facebook: '',
          instagram: '',
          linkedin: '',
          website: '',
          twitter: '',
          tiktok: '',
        },
        memberSince: {
          visible: true,
        },
      },
      icon: {
        style: 'simple',
        impactColors: {
          carbon: {
            backgroundColor: '#FFB339',
            textColor: '#212121',
          },
          trees: {
            backgroundColor: '#49E2A7',
            textColor: '#212121',
          },
          plastic: {
            backgroundColor: '#1FAEF0',
            textColor: '#212121',
          },
          kelp: {
            backgroundColor: '#7182F7',
            textColor: '#212121',
          },
          water: {
            backgroundColor: '#6074FF',
            textColor: '#FCFCFC',
          },
        },
        impactDisplay: {
          carbon: true,
          trees: true,
          plastic: true,
          kelp: true,
          water: true,
          monthsEarthPositive: true,
          hideZeroValues: true,
        },
      },
      goalsAchievements: {
        en: {
          goals: [],
          achievements: [],
        },
        de: {
          goals: [],
          achievements: [],
        },
      },
    },
    previewMode: false,
    loading: false,
    activeDevice: 'desktop',
    publishLoading: false,
    lang: 'en',
    valid: true,
  }),
  getters: {},
  actions: {
    async fetchEditorSettings(id: string) {
      try {
        const { data } = await getEditorSettings(id)
        this.$patch({ editor: { ...data.publicPageEditorSettings } })

        if (inIFrame()) {
          dispatchMessage(window.parent, {
            type: EVENT_TYPES.UPDATE_EDITOR_STORE,
            payload: { editor: { ...data.publicPageEditorSettings } },
          })
        }
      } catch (e) {
        console.error(e)
      }
    },
    async saveEditorSettings(payload: Partial<IEditorSettings>) {
      try {
        const { data } = await updateEditorSettings(payload)
        this.$patch({ editor: { ...data } })
      } catch (e) {
        console.error(e)
        return Promise.reject(e)
      }
    },
  },
})
